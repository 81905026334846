import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProfilemenumobileComponent } from './profilemenumobile.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [CommonModule, IonicModule, FormsModule, RouterModule],
    exports: [
        ProfilemenumobileComponent
    ],
    declarations: [ProfilemenumobileComponent],
})
export class ProfilemenumobileModule { }